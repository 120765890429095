















































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsListingCard from '@/components/VsListingCard/Index.vue'
import { ISubAreaList } from '@/modules/subscriberArea/views/index.vue'

@Component({
    name: 'SubscriberAreaListCard',
    components: {
        VsDropdownButton,
        VsListingCard,
    },
})
export default class extends Vue {
    @Prop({ default: null, required: false }) list!: ISubAreaList

    get badgeColor () {
        switch (this.list.status || '') {
            case 'Unsubscribed':
                return 'alert'
            case 'Subscribed':
                return 'success'
            case 'Opt-In Pending':
            default:
                return 'default'
        }
    }

    get badgeLabel () {
        switch (this.list.status || '') {
            case 'Unsubscribed':
                return 'Disiscritto'
            case 'Subscribed':
                return 'Iscritto'
            case 'Opt-In Pending':
                return 'In attesa conferma iscrizione'
            default:
                return ''
        }
    }

    get statusDate () {
        switch (this.list.status || '') {
            case 'Unsubscribed':
                return this.list.unsubscription_date
            case 'Subscribed':
                return this.list.subscription_date
            case 'Opt-In Pending':
                return this.list.optin_date
            default:
                return ''
        }
    }
}
