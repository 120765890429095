






















































































import { Component, Vue, Watch } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import { changeContactStatus, forgetContact, getSubscriberRecipients, getSubscriberSuppression } from '@/api/consoleApi/subscriberArea'
import { SubscriberAreaModule } from '@/store/modules/subscriberArea'
import SubscriberAreaTop from '@/modules/subscriberArea/components/SubscriberAreaTop/Index.vue'
import SubscriberAreaListCard from '@/modules/subscriberArea/components/SubscriberAreaListCard/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import moment from 'moment'
import VsLoader from '@/components/VsLoader/Index.vue'

export interface ISubAreaList {
    list_id: number
    contact_id: number
    list_name: string
    optin_date: string
    status: 'Opt-In Pending' | 'Subscribed' | 'Unsubscribed'
    subscription_date: string
    unsubscription_date: string
}

@Component({
    name: 'SubscriberAreaIndex',
    components: {
        VsContainer,
        VsSectionHeader,
        VsDropdownButton,
        SubscriberAreaTop,
        SubscriberAreaListCard,
        VsConfirm,
        VsFullTopBarLayout,
        VsLoader,
    },
})
export default class extends Vue {
    loading = false
    lists: ISubAreaList[] = []
    smsSup = false
    emailSup = false
    $refs!: any
    total = 0

    get subscriberAreaUser () {
        return SubscriberAreaModule.user
    }

    get userContact () {
        return this.subscriberAreaUser?.credential || ''
    }

    beforeMount () {
        this.getLists()
        this.getSubscriberSuppression()
    }

    async getLists () {
        try {
            const resp = await getSubscriberRecipients()
            this.lists = resp.data.data
        } catch (e) {
            console.log(e)
            this.lists = []
        }
    }

    async getSubscriberSuppression () {
        try {
            const resp = await getSubscriberSuppression()
            for (const supp of resp.data.data) {
                if (supp.email_address) {
                    this.emailSup = true
                }
                if (supp.mobile) {
                    this.smsSup = true
                }
            }
        } catch (e) {
            console.log(e)
            this.emailSup = false
            this.smsSup = false
        }
    }

    async subscribeContact (list: any) {
        try {
            await this.$refs.subscribeContactConfirm.openConfirm()
        } catch (e) {
            return
        }
        this.loading = true
        try {
            await changeContactStatus(list.list_id, list.contact_id, {
                status: 'Subscribed',
                timestamp: moment().unix(),
            })
            this.$root.$vsToast({
                heading: 'Contatto iscritto con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.getLists()
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante l\' iscrizione del contatto',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
        this.loading = false
    }

    async unsubscribeContact (list: any) {
        try {
            await this.$refs.unsubscribeContactConfirm.openConfirm()
        } catch (e) {
            return
        }
        this.loading = true
        try {
            await changeContactStatus(list.list_id, list.contact_id, {
                status: 'Unsubscribed',
                timestamp: moment().unix(),
            })
            this.$root.$vsToast({
                heading: 'Contatto disiscritto con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.getLists()
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la disiscrizione del contatto',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
        this.loading = false
    }

    async unsubscribeAllContact () {
        try {
            await this.$refs.unsubscribeAllContactConfirm.openConfirm()
        } catch (e) {
            return
        }
        this.loading = true
        for (const list of this.lists) {
            try {
                await changeContactStatus(list.list_id, list.contact_id, {
                    status: 'Unsubscribed',
                    timestamp: moment().unix(),
                })
            } catch (e) {
                console.log(e)
            }
        }

        this.$root.$vsToast({
            heading: 'Contatto disiscritto con successo',
            aspect: VsToastAspectEnum.success,
            timeout: 3000,
        })
        this.getLists()
        this.loading = false
    }

    async forgetContact () {
        try {
            await this.$refs.forgetContactConfirm.openConfirm()
        } catch (e) {
            return
        }
        try {
            await forgetContact()
            this.$root.$vsToast({
                heading: 'Contatto obliato con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            const userMongoId = this.subscriberAreaUser?.userMongoId || ''
            SubscriberAreaModule.logout()
            this.$router.replace({
                name: 'subscriberAreaLogin',
                params: {
                    userId: userMongoId,
                },
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la cancellazione dei dati del contatto',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
    }
}
